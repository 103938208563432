// 'use strict'

// import { cloneDeep } from 'lodash';

class FieldType {
    constructor(options) {
        this._options = options
    }

    _clone(ops) {
        let obj = {
            id: ops.id,
            alias: ops.alias || 'Field 1',
            value: ops.value || '',
            type : ops.type,
    
            hasErrors: {
                error: ops.errors || false,
                message: ops.errMsg
            },
    
            rules     : {
                required: ops.required || false,
    
                isNumber: ops.isNumber,
                isDate: ops.isDate,
                amount: ops.amount,
                percent: ops.percent,
    
                minNum: ops.minNum,
                maxNum: ops.maxNum,
                minLength: ops.minLen,
                maxLength: ops.maxLen,

                noSpaces: ops.noSpaces,
                accountCode: ops.accountCode,

                inList: ops.inList,
                isURL: ops.isURL
                
            }
        }
        // Inject any additional key:value pairs
        if (ops._additional) {
            Object.entries(ops._additional).forEach((key, val)=>{
                obj[key] = val;
            })
        }
        return obj;
    }

    default = (ops) => this._clone(ops);
    
    text = (ops) => {
        let _ = this._clone(ops);
        _.type = 'text';
        // console.log('FieldType->text->_', _);
        return _;
    }

    number = (ops) => {
        let _ = this._clone(ops);
        _.type = 'number';
        _.rules.isNumber = true;
        return _;
    }

    amount = (ops) => {
        // console.log('FieldType->amount->ops', ops);
        let _ = this._clone(ops);
        // console.log('FieldType->amount->_', _);
        _.type = 'amount';
        _.rules.amount = true;
        return _;
    }

    percent = (ops) => {
        let _ = this._clone(ops);
        _.type = 'percent';
        _.rules.percent = true;
        return _;
    }

    date = (ops) => {
        let _ = this._clone(ops);
        _.type = 'date';
        _.rules.isDate = true;
        return _;
    }

    list = (ops) => {
        let _ = this._clone(ops);
        _.type = 'list';
        return _;
    }

    checkbox = (ops) => {
        let _ = this._clone(ops);
        _.type = 'checkbox';
        return _;
    }
    
}

export default { FieldType }
