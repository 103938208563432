import Constants from "../utils/constants";

export const AccountGrid = (typeMap) => {
    return {
        colDefWide : [{
            headerName: 'Type',
            field: 'type',
            width: 220,
            cellRenderer: (params)=>{
                let code = params.value;
                let name = typeMap[code];
                let icon = `<img 
                    width="30px" 
                    style="background: white;"
                    src="../assets/img/accounts/icons/${code}.png"
                    alt="${name}" />`;
                return icon + '&nbsp;&nbsp;<span>' + name + '</span>';
            }
        },{
            headerName: 'Account Name',
            field: 'name',
            width: 240,
            cellRenderer: (params) => {
                return `${params.value} ${(params.data.isOwner ? '' : '(Shared)')}`
            }
        },{
            headerName: 'Actions',
            width: 100,
            cellRenderer: (params)=>{
                return (
                    `<a  key={account._id} href="/transactions/${params.data._id}">View Trans</a>`
                )
            }
        },{
            headerName: 'Account Code',
            field: 'code',
            width: 180
        },{
            headerName: 'Description',
            field: 'desc',
            width: 220
        },{
            headerName: 'Inception',
            field: 'date',
            width: 120,
            cellRenderer: (params) => {
                return params.value?.substr(0,10);
            }
        },{
            headerName: 'Current Balance',
            field: 'bal',
            cellStyle: {textAlign: "right"},
            width: 180,
            cellRenderer: (params) => {
                return Constants.toCurrency(Number(params.value), params.data.curr);
            }
        },{
            headerName: 'Currency',
            field: 'curr',
            width: 100
        },{
            headerName: 'Last Updated',
            field: 'updated_at',
            width: 160,
            cellRenderer: (params) => {
                return Constants.timeAgo(params.value)
            }
        }],

        colDefCompact : [{
            headerName: 'Type',
            field: 'type',
            width: 54,
            cellRenderer: (params)=>{
                let code = params.value;
                let name = typeMap[code];
                let icon = `<img 
                    width="30px" 
                    style="background: transparent;"
                    src="../assets/img/accounts/icons/${code}.png"
                    alt="${name}" />`;
                return `<a  key={account._id} href="/transactions/${params.data._id}">${icon}</a>`;
            }
        }/* ,{
            headerName: 'Account Name',
            field: 'name',
            width: 240
        } */ ,{
            headerName: 'Account Code',
            field: 'code',
            width: 140,
            cellRenderer: (params) => {
                return `<a  key={account._id} href="/transactions/${params.data._id}">${params.value}</a>`;
            }
        }/*,{
            headerName: 'Inception',
            field: 'date',
            width: 120,
            cellRenderer: (params) => {
                return params.value?.substr(0,10);
            }
        } *//* ,{
            headerName: 'Currency',
            field: 'curr',
            width: 100
        } */,{
            headerName: 'Updated',
            field: 'updated_at',
            width: 120,
            cellRenderer: (params) => {
                return Constants.timeAgo(params.value)
            }
        },{
            headerName: 'Balance',
            field: 'bal',
            cellStyle: {textAlign: "right"},
            width: 140,
            cellRenderer: (params) => {
                return Constants.toCurrency(Number(params.value), params.data.curr);
            }
        }/* ,{
            headerName: 'Actions',
            cellRenderer: (params)=>{
                return (
                    `<a  key={account._id} href="/transactions/${params.data._id}">View Trans</a>`
                )
            }
        } */]
    }
}