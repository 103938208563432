import moment from 'moment-timezone';
const FieldType = require('./FieldType').default.FieldType;
export const validator = {

  required: (field, rule) => {
    if (!rule || (field && field.length > 0)) return { passed: true, message: '' }
    // if (rule && field) {
    //   if (field.length > 0) return { passed: true, message: '' };
    //   else return { passed: false, message: 'Field is required' };
    // } 
    else 
      return { passed: false, message: 'Field is required' };
  },

  maxLength: (field, rule) => {
    if (field && field.length > rule) {
      return {
        passed: false,
        message: `Field cannot be greater than ${rule} ${rule > 1 ? 'characters' : 'character'}`
      };
    } else
      return { passed: true, message: '' };
  },

  minLength: (field, rule) => {
    if (field && field.length >= rule) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field must be at least ${rule} ${rule > 1 ? 'characters' : 'character'} long`
      };
  },

  noSpaces: (field, rule) => {
    const NO_SPACES_ALLOWED = /\s/;
    let regex = RegExp(NO_SPACES_ALLOWED);
    if (!regex.test(field)) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field cannot contain any spaces`
      };
  },

  noSpacesAround: (field, rule) => {
    const NO_SURROUNDING_SPACES = '^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$';
    let regex = RegExp(NO_SURROUNDING_SPACES);
    if (regex.test(field)) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field cannot start or end with spaces`
      };
  },

  minTomorrow: (field, rule) => {
    let today = moment().format('YYYY-MM-DD');
    if (moment(field).isAfter(today)) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field must be greater than today`
      };
  },

  isDate: (field, rule) => {
    if (moment(field).isValid()) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field date is invalid`
      };
  },

  accountCode: (field, exp) => {
    const ACCOUNT_CODE = '^[A-Z0-9]+(_[A-Z0-9]+)*$';
    let regex = RegExp(ACCOUNT_CODE);
    if (regex.test(field)) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Account code should start with upper-case letter and can contain only uppercase alpha-numerics and can contain only underscores (_).`
      };
  },

  amount: (field, rule) => {
    var AMOUNT_CHECK = /^(\d+|\d+\.\d{1,2})$/;
    let regex = RegExp(AMOUNT_CHECK);
    if (regex.test(field)) return {passed: true, message: ''};
    else 
      return {
        passed: false,
        message: `Amount is invalid.`
      };
  },

  percent: (field, rule) => {
    var PERC_CHECK = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
    let regex = RegExp(PERC_CHECK);
    if (regex.test(field)) return {passed: true, message: ''};
    else 
      return {
        passed: false,
        message: `Percentage is invalid.`
      };
  },
  
  isNumber: (field, exp) => {
    const AMOUNT = '^[0-9]+$';
    let regex = RegExp(AMOUNT);
    if (regex.test(field)) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Invalid number field value.`
      };
  },

  maxNum: (field, rule) => {
    if (field && field <= rule) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field value ${field} is greater than ${rule}.`
      };
  },

  minNum: (field, rule) => {
    if (field && field >= rule) return { passed: true, message: '' };
    else
      return {
        passed: false,
        message: `Field value ${field} is less than minimum ${rule}.`
      };
  },


  inList: (item, list) => {
    let found = list.filter(_item => _item.code === item)[0];
    if (found) 
      return {passed: true, message: ''};
    else 
      return {
        passed: false,
        message: `Value must be an item in the list.`
      };
  },

  isURL: (field, rule) => {
    var URL_CHECK = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    let regex = RegExp(URL_CHECK);
    if (regex.test(field)) return {passed: true, message: ''};
    else 
      return {
        passed: false,
        message: `URL is invalid.`
      };
  }
  
};

export const fieldTypes = {

  Text: (_id=null, _alias, _required=false, _valAsStr='', _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _valAsStr,
      required: _required
    };
    return (new FieldType()).text({ ...ops, ..._addRules });
  },

  Number: (_id=null, _alias, _required=false, _val=0, _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _val,
      required: _required
    };
    return (new FieldType()).number({ ...ops, ..._addRules });
  },

  Amount: (_id=null, _alias, _required=false, _valAsStr='0.00', _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _valAsStr,
      required: _required
    };
    return (new FieldType()).amount({ ...ops, ..._addRules });
  },

  Percent: (_id=null, _alias, _required=false, _val=0.00, _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _val,
      required: _required
    };
    return (new FieldType()).percent({ ...ops, ..._addRules });
  },

  Date: (_id=null, _alias, _required=false, _valYYYYMMDD=moment().format('YYYY-MM-DD'), _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _valYYYYMMDD,
      required: _required
    };
    return (new FieldType()).date({ ...ops, ..._addRules });
  },

  List: (_id=null, _alias, _required=false, _defaultVal='', _addRules={ inList: [] }) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _defaultVal,
      required: _required
    };
    return (new FieldType()).text({ ...ops, ..._addRules });
  },

  Checkbox: (_id=null, _alias, _required=false, _val=false, _addRules={}) => {
    let ops = {
      id: _id,
      alias: _alias,
      value: _val,
      required: _required
    };
    return (new FieldType()).checkbox({ ...ops, ..._addRules });
  },

}
