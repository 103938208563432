import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { withStyles, styled } from '@material-ui/core/styles';
import { Button, Typography, LinearProgress, Dialog, Grid, Box, Paper, Tabs, Tab } from '@material-ui/core';

import '../tran/Tran.css'
import {TranStyle} from '../tran/TranStyle';
import { accountCCStmtSummaryObject, mapAccount } from './acctObject';
import mapStateToProps from '../actions/stateToProps';
import UIHandler from '../utils/UIHandler';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import { AgGridReact } from 'ag-grid-react';

// import * as React from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
		  {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


let classes = null;
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));
class AccountStmtSummary extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		sqlErrorMessage: '',
		account: mapAccount(this.props.account),
		summary: _cloneDeep(accountCCStmtSummaryObject),
		latestStatement: {},
		allStatements: [],
		tabValue: 0,

	};

	// const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

handleChange = (event, newValue) => {
	//     setValue(newValue);
	console.log('event', event, 'newValue', newValue);
	this.setState({
		tabValue: newValue
	})
	  };

    componentDidMount() {
        this.handleFetchLatestStatement();
    }

	handleFetchLatestStatement = async () => {
		let _account = this.props.account;
		let url = `${Constants.URLs.STATEMENT}/ac/${_account._id}/latest`;
		console.log('URL', url);
		let _latestStatement = this.state.latestStatement;
		let _summary = this.state.summary;
		getInstance().get(url)
		.then(response=>{
			console.log("latest statement fetched", response.data)
			_latestStatement = response.data;
			_summary.last_statement_date.value = _latestStatement.info.statement_date;
			let {
				// base_min_due,
				min_due,
				total_due,
				payment_due_date,
				last_payment_amnt,
				last_payment_on
			} = _latestStatement.statement;
			_summary.last_statement_due.value = payment_due_date;
			_summary.last_statement_min.value = min_due;
			_summary.last_statement_bal.value = total_due;
			_summary.last_payment_amnt.value = last_payment_amnt;
			_summary.last_payment_date.value = last_payment_on;
		})
		.catch((err)=>{
			console.error(err);
			this.props.enqueueSnackbar('Error: Error while fetching latest statement.' + err.message, {
				variant : 'error'
			});
		})
		.finally(()=>{
			this.setState({
				latestStatement: _latestStatement,
				summary: _summary
			})
			let url2 = `${Constants.URLs.STATEMENT}/ac/${_account._id}/all`
			getInstance().get(url2)
			.then((response)=>{
				console.log('statements', response.data)
				this.setState({
					allStatements: response.data
				})
			})
			.catch(err=>{
				console.error(err);
				this.props.enqueueSnackbar('Error: Error while fetching all statements.' + err.message, {
					variant : 'error'
				});
			}).finally(()=>{
				console.log('statements fetched sucessfully.')
			})
		})
	}
	
	preparePayload = (_data = null) => {
		let _payload = {};
		let _keys = Object.keys(_data);
		for (let key of _keys) {
			if (key !== '_id') {
				_payload[key] = _data[key].value;
			}
		}
		return _payload;
	};


	handleCreateTransaction = async () => {
		// let _account = this.state.account;
		// let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		// let preparedReleaseObject = this.preparePayload(_account);
        // let responseErr = '';
        // let isAfter = false;
        // try {
        //     isAfter = moment(this.state.freqencyEndDate).isAfter(this.state.transaction.date);
        // } catch (error) {
        //     console.error(error);
        // }
        // if (this.state.repeatFlag && isAfter) {
        //     //console.log('valid frequency end date found')
        //     preparedReleaseObject.repeat = "Y";
        //     preparedReleaseObject.schedule = [];
        //     let current = this.state.transaction.date;
        //     let max = 24;
        //     let index = 0;
        //     do {
        //         index++;
        //         let next = moment(current).add(1, this.state.frequencyInc).format("YYYY-MM-DD");
		// 		//console.log(`next ${next} current ${current}`)
        //         preparedReleaseObject.schedule.push(next);
        //         isAfter = moment(this.state.freqencyEndDate).isAfter(next);
		// 		//console.log(`isAfter ${isAfter}`)
        //         current = next;
		// 		//console.log(`current: ${current}`)
        //     } while (isAfter && index < max);
        //     if (isAfter) {
        //         this.props.enqueueSnackbar(`Max ${max} transactions can be added at once.`, {
		// 		    variant : 'info'
		// 	    });
        //     }
        //     //console.log("preparedReleaseObject.schedule: ", JSON.stringify(preparedReleaseObject.schedule));
        // } else {
        //     //console.log('invalid frequency end date found')
        // }

		// await getInstance()
		// 	.post(`${Constants.URLs.ACCOUNTS}/${_account._id}/schedule`, preparedReleaseObject)
		// 	.then(async (response) => {
		// 		if (response.status === 400) {
		// 			console.log(response)
		// 			this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
		// 				variant : 'error'
		// 			});
		// 			responseErr = response.data.message
		// 		}
		// 		else {
		// 			this.props.enqueueSnackbar('Transaction created successfully', {
		// 				variant : 'success'
		// 			});
		// 			// _transaction = _cloneDeep(tranObject)
		// 			// _account = _cloneDeep(acctObject)
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
        //         responseErr = error.message;
        //         let msg = 'An error occurred from the server';
        //         try {
        //             msg = error.response.data.message
        //         } catch (error) {}

		// 		this.props.enqueueSnackbar(msg, {
		// 			variant : 'error'
		// 		});
		// 	})
		// 	.finally(()=>{
		// 		_actionInProgress.disableActionButton = false;
		// 		_actionInProgress.openLinearLoading = false;
		// 		this.setState({
		// 			sqlErrorMessage  : responseErr,
		// 			// transaction: _transaction,
		// 			// account: _account,
		// 			actionInProgress : _actionInProgress
		// 		});
		// 		if (responseErr === '') {
		// 			this.props.onSubmit();
		// 		}
		// 	})
	};

	handleUpdateTransaction = async () => {
		// this.props.enqueueSnackbar('This action is not ready yet', {
		// 	variant : 'warning'
		// });
		// return;
		// let _account = this.state.account;
		// let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		// let _actionInProgress = this.state.actionInProgress;
		// _actionInProgress.disableActionButton = true;
		// _actionInProgress.openLinearLoading = true;
		// this.setState({
		// 	actionInProgress : _actionInProgress
		// });

		let _summary = this.state.summary;
		
		let preparedPayload = this.preparePayload(_summary);
		console.log('preparedPayload', preparedPayload);
		return;
		//inject chit_info
		// preparedPayload.chit_info = this.state.tranChitInfo;
		// // console.log('preparedReleaseObject', preparedReleaseObject)
		// let responseErr = '';
		// await getInstance()
		// 	.patch(`${Constants.URLs.ACCOUNTS}/${_account._id}/schedule`, preparedPayload)
		// 	.then(async (response) => {
		// 		if (response.status === 400) {
		// 			console.log(response)
		// 			this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
		// 				variant : 'error'
		// 			});
		// 			responseErr = response.data.message
		// 		}
		// 		else {
		// 			this.props.enqueueSnackbar('Transaction updated successfully', {
		// 				variant : 'success'
		// 			});
		// 			// _transaction = _cloneDeep(tranObject)
		// 			// _account = _cloneDeep(acctObject)
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
        //         let msg = 'An error occurred from the server';
        //         try {
        //             if (error.response.data.message) msg = error.response.data.message
        //         } catch (error) {}
        //         responseErr = msg;
		// 		this.props.enqueueSnackbar(msg, {
		// 			variant : 'error'
		// 		});
		// 	})
		// 	.finally(()=>{
		// 		_actionInProgress.disableActionButton = false;
		// 		_actionInProgress.openLinearLoading = false;
		// 		this.setState({
		// 			sqlErrorMessage  : responseErr,
		// 			// transaction: _transaction,
		// 			// account: _account,
		// 			actionInProgress : _actionInProgress
		// 		});
		// 		if (responseErr === '') {
		// 			this.props.onSubmit();
		// 		}
		// 	})
	}

	/**
	 * Render UI components
	 */

	renderFormTitle(title=null) {
		return (
			<UIHandler
				type={"header"}
				field={{
					// heading: this.props.account.name,
					subheading: "Statement Dashboard"
				}}
				/>
		)
	}

	renderSQLError() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<Typography 
					variant="caption" 
					align="center" 
					className={classes.sqlErrorMessage}>
					{this.state.sqlErrorMessage}
				</Typography>
			</Grid>
		)
	}

	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				{!this.props.readOnly && <Button
					variant="contained"
                    color="primary"
                    data-cy="btnTranCancel"
					className={classes.button}
					onClick={() => this.props.onCancel()}>
					Cancel
				</Button>}
				{false && this.props.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={()=>this.props.onEnableEdit()}>
					Edit
				</Button>}
				{!this.props.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleUpdateTransaction}>
					Update
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}

	genericField(field, type="text", noGrid=true) {
		return (
			<UIHandler 
				field={field} 
				noGrid={noGrid} 
				fieldStyle={{margin: '10px'}} 
				type={type} 
				onRefreshed={(_field)=>{
					let _summary = this.state.summary;
					_summary[field.id] = _field;
					this.setState({
						summary: _summary
					})
				}}
				readOnly={this.props.readOnly} />
		)
	}

	noRowsTemplate = `<span>No Transactions were recorded in this statement.</span>`;

		renderStatements() {
		return (
			this.state.allStatements.map((stmt)=>{
				if (stmt.expires_in <= 10) { // Need at least 10 sec before expiry
					return (
						<Grid item xs={3} key={stmt.statement_date_yyyymmdd}>
							<Item>{stmt.statement_date}{stmt.isEmpty ? '*' : ''}</Item>
						</Grid>
					)
				} else {
					return (
						<Grid item xs={3} key={stmt.statement_date_yyyymmdd}>
							<Item><a target="_blank" rel="noopener noreferrer" href={stmt.pdf}>{stmt.statement_date}{stmt.isEmpty ? '*' : ''}</a></Item>
						</Grid> 
					)
				}
			})
		)
	}



	renderAsModal() {
		const { classes } = this.props;
        // const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		let value = this.state.tabValue;

		let {
			// limit,
			// available,
			last_statement_date,
			last_statement_due,
			last_statement_min,
			last_statement_bal,
			last_payment_date,
			last_payment_amnt
		} = this.state.summary;
		let {
			transactions,
			pdfs
		} = this.state.latestStatement;
		let pdfUrl = (pdfs && pdfs[0] && pdfs[0].url) ? pdfs[0].url : null;
		// let _stmt = this.state.latestStatement;
		// console.log('transactions', transactions)
		// let {
		// 	base_min_due,
		// 	min_due,
		// 	total_due,
		// 	payment_due_date,
		// 	last_payment_amnt,
		// 	last_payment_on
		// } = statement;
		//TODO: Build the dashboard to display latest statement summary
		//TODO: Build a drop down with all the available statements for the account
		//TODO: Build a simple grid (table) to display statement transactions
		//TODO: Provide a link on top of the table to download the PDF statement from the url.
		//TODO: Provide a second tab on which provide a drop down with Years (all available statement years)
		//TODO: List all current year PDFs (link) when the tab is loaded.
		//TODO: List all PDFs of the year (links) when drop down value chages
		return (
			<div>
				<Grid container className={classes.newAccountModalActions} data-cy="mdlCreateNewTranGrid">
					{/* {this.renderFormTitle()} */}
					{/* {this.renderFormFieldType()} */}


<Box sx={{ width: '100%' }}>
	<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
		<Tabs value={value} onChange={this.handleChange} aria-label="basic tabs example">
			<Tab label="Overview" {...a11yProps(0)} />
			<Tab label="Statements" {...a11yProps(1)} />
			{/* <Tab label="Downloads" {...a11yProps(2)} /> */}
		</Tabs>
	</Box>
	<CustomTabPanel value={value} index={0}>
			{/* Tab Overview */}

						<Grid container spacing={1} style={{ backgroundColor: '#381b6370', padding: '10px' }}>
							<Grid item xs={3} style={{ alignSelf: 'start'}}>
								<Item><strong>Statement Date</strong></Item>
							</Grid>
							<Grid item xs={3}>
								<Item>{last_statement_date.value}</Item>
							</Grid>
							
							<Grid item xs={3}>
								<Item><strong>Total Due</strong></Item>
							</Grid>
							<Grid item xs={3}>
								<Item>{last_statement_bal.value}</Item>
							</Grid>
							
							<Grid item xs={3}>
								<Item><strong>Minimum Due</strong></Item>
							</Grid>
							<Grid item xs={3}>
								<Item>{last_statement_min.value}</Item>
							</Grid>
							
							<Grid item xs={3}>
								<Item><strong>Due On</strong></Item>
							</Grid>
							<Grid item xs={3}>
								<Item>{last_statement_due.value}</Item>
							</Grid>
							
							{last_payment_date.value && 
							<Grid item xs={3}>
								<Item><strong>Last Payment</strong></Item>
							</Grid>}{last_payment_date.value && 
							<Grid item xs={3}>
								<Item>{last_payment_amnt.value}</Item>
							</Grid>}{last_payment_date.value && 
							<Grid item xs={3}>
								<Item><strong>Last Payment On</strong></Item>
							</Grid>}{last_payment_date.value && 
							<Grid item xs={3}>
								<Item>{last_payment_date.value}</Item>
							</Grid>}

							{pdfUrl && 
							<Grid item xs={12}>
								<Item><a target="_blank" rel="noopener noreferrer" href={pdfUrl}>Download PDF</a></Item>
							</Grid>}
						</Grid>




						<Grid item  md={12} lg={12} xs={12} style={{ marginTop: '4px' }}>
							<h3>Transactions:</h3>
							<div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
								<AgGridReact
									gridOptions={{
										defaultColDef: {
											// resizable: true,
											// sortable: true,
										},
									}}
									columnDefs={[{
										header: "date",
										field: "date",
										width: 80,
										editable: true
									},{
										header: "desc",
										field: "desc",
										width: 200,
										// cellRenderer: (params) => (params.value).toFixed(0) + " (" + Constants.formatBytes(params.value) + ")"
									},{
										header: "type",
										field: "type",
										width: 120,
										// cellRenderer: (params) => {
										// 	let date = '';
										// 	try {
										// 		date = params.value.substring(0,16).replace('T', ' ') + " GMT";
										// 	} catch (error) {
										// 		//
										// 	}
										// 	return date;
										// }
									},{
										header: "Amount",
										field: "amnt",
										width: 100,
										// cellRenderer: (params) => {
										//     return (params.value > Date.now() ? "Active" : "Expired")
										// }
									}]}
									rowData={transactions}
									onGridReady={params => {
										this.receiptGridApi = params.api;
										this.receiptGridColumnApi = params.columnApi;
										this.receiptGridApi.sizeColumnsToFit();
									}}

									// getRowClass={this.handleRowHighlights}
									rowSelection="single"
									// onSelectionChanged={this.onReceiptSelectionChanged.bind(this)}
									// rowDragManaged={true}
									animateRows
									// pagination={!this.state.gridEditMode}
									// paginationPageSize={25}
									enableCellTextSelection="true"
									ensureDomOrder="true"
									overlayNoRowsTemplate={this.noRowsTemplate}
								/>
							</div>
						</Grid>

	</CustomTabPanel>
	<CustomTabPanel value={value} index={1}>
	{/* Tab Statements */}
		<Grid container spacing={1} style={{ backgroundColor: '#381b6370', padding: '10px' }}>
			{/* {pdfUrl && 
				<Grid item xs={3}>
					<Item><a target="_blank" href={pdfUrl}>{this.state.latestStatement.info.statement_date}</a></Item>
				</Grid>} */}
			{this.state.allStatements && this.state.allStatements.length > 0 && this.renderStatements()}
		</Grid>
		<Typography>* - No trasctions for the period.</Typography>
	</CustomTabPanel>
	{/* <CustomTabPanel value={value} index={2}>
	Tab Downloads
	</CustomTabPanel> */}
</Box>
					{/* <Grid item  md={12} lg={12} xs={12}>
						{this.genericField(limit)}
						{this.genericField(available)}
						</Grid>
					
					<Grid item  md={12} lg={12} xs={12}>
						
						</Grid> */}
					{this.renderSQLError()}
					{this.renderActions()}
					{this.renderLinearLoader()}
				</Grid>
			</div>
		);
	}

	render() {
		if (this.props.asDialog) {
			return (
				<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
						{this.renderAsModal()}
				</Dialog>
			)
		}
		return this.renderAsModal();
	}
}

AccountStmtSummary.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
	asDialog: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	//Needed for update transaction
	actionUpdate: PropTypes.bool.isRequired,
	// transaction: PropTypes.object,
	multiMode: PropTypes.bool,
	transactions: PropTypes.array.isRequired,
	readOnly: PropTypes.bool,
	onEnableEdit: PropTypes.func
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(AccountStmtSummary)));
