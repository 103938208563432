import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';

export default class 
ServiceHandler extends ApiHandler {

    constructor(callerComponent) {
        super(callerComponent);
        this.baseUrl = Constants.URLs.SERVICE;
    }

    emailTransactions() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to recalculate.');
			return;
		}
        let url = `${this.baseUrl}/email-trans/${ac}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url).then((response) => {
                this.showSuccess(response.data.message);
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while emailing account transactions. Try again.`))
        });
    }


	stmtPreviewAction(accountId) {
        let url = `${Constants.URLs.STATEMENT}/preview/ac/${accountId}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().get(url).then((response) => {
                let html = response.data;
				let previewTab = window.open(`/statement-preview/ac/${accountId}`);
				previewTab.document.write(html);
	
				//to inject pdf instead use	
				// previewTab.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}</style></head>");
				// previewTab.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64Data)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
				previewTab.document.close();

                // this.showSuccess(response.data.message);
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while previewing account statement. Try again.`))
        });
		// this.setState({
		// 	openOverlay: true
		// })
		// this.reorderAccount().finally(()=>{
			// this.getInstance().get(`${Constants.URLs.STATEMENT}/preview/ac/${ac._id}`)
			// .then((response)=>{
			// 	let html = response.data;
			// 	let previewTab = window.open(`/statement-preview/ac/${ac._id}`);
			// 	previewTab.document.write(html);
	
			// 	//to inject pdf instead use	
			// 	// previewTab.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}</style></head>");
			// 	// previewTab.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64Data)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
			// 	previewTab.document.close();
			// })
            // .finally(()=>{
			// 	this.setState({
			// 		openOverlay: false
			// 	})
			// })
		// })
	}

}
